/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    li: "li",
    a: "a",
    section: "section",
    div: "div",
    img: "img",
    canvas: "canvas",
    h3: "h3",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {TableOfContents, HeadedStatement, Button} = _components;
  if (!Button) _missingMdxReference("Button", true);
  if (!HeadedStatement) _missingMdxReference("HeadedStatement", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, null, React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#what-is-a-pact-"
  }, "What is a PACT?")), React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#six-dimensions-of-pact"
  }, "Six dimensions of PACT"))), React.createElement(_components.section, {
    className: "uk-section uk-position-relative uk-section-image-content uk-background-light uk-overflow-hidden  ",
    id: "what-is-a-pact-"
  }, React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-1"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-2"
  }), React.createElement(_components.div, {
    className: "uk-position-two-column-text-circle-3"
  }), React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.div, {
    className: "uk-width-image-column  uk-image-float-right uk-margin-large-bottom@m uk-margin-medium-bottom"
  }, React.createElement(_components.div, {
    className: "uk-background-primary uk-overflow-hidden uk-position-relative  uk-border-rounded"
  }, React.createElement(_components.div, {
    className: "uk-width-1-1 uk-height-1-1 uk-background-sixth uk-position-absolute"
  }), React.createElement(_components.div, {
    className: "uk-cover-container"
  }, React.createElement(_components.img, {
    src: "/assets/images/what-is-pact/image-content-1.jpg",
    alt: "A sunlit savannah with snow-capped mountains in the distance",
    className: " uk-utility-object-fit-cover"
  }), React.createElement(_components.canvas, {
    width: "450",
    height: "600"
  })))), React.createElement(_components.h3, {
    className: "uk-style-secondary uk-text-ls-reduce uk-text-5"
  }, "Permanent, Additional, Carbon Tonnes: the Cambridge PACT is a new approach to carbon credits."), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Our framework allows direct comparison for the first time between nature-based solutions for carbon storage (NBS), such as forest preservation or reforestation, on an equal footing with carbon capture and storage (CCS) or geosequestration.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "We use cutting-edge methods developed at the University of Cambridge for assessing additionality; a new approach to impermanence; and a transparent, self-correcting protocol for addressing uncertainty. Together, these innovations allow nature-based solutions, carbon capture and storages, and in principle any other offset projects to be compared on a like-for-like basis.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "This is supported by an automated, scalable approach to monitoring, reporting and verification (MRV) which uses satellite remote sensing for rapid initial assessment and ongoing monitoring of the quality of each project.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "By increasing confidence from those buying carbon credits in NBS credits, and reducing the burden of compliance for conservation projects, we hope to create new funding opportunities and encourage more NBS carbon projects to enter the credits market.")), React.createElement(_components.div, {
    className: "uk-width-image-column  uk-image-float-left uk-margin-large-bottom@m uk-margin-medium-bottom"
  }, React.createElement(_components.div, {
    className: "uk-background-primary uk-overflow-hidden uk-position-relative  uk-border-rounded"
  }, React.createElement(_components.div, {
    className: "uk-width-1-1 uk-height-1-1 uk-background-sixth uk-position-absolute"
  }), React.createElement(_components.div, {
    className: "uk-cover-container"
  }, React.createElement(_components.img, {
    src: "/assets/images/what-is-pact/image-content-2.jpg",
    alt: "A false-colour satellite image of a river delta",
    className: " uk-utility-object-fit-cover"
  }), React.createElement(_components.canvas, {
    width: "450",
    height: "600"
  })))), React.createElement(_components.h2, null, "Using the latest satellite remote sensing technology"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Assessing our global progress towards a particular natural solution (e.g. halting tropical deforestation) is now possible. We leverage progress in earth observations to perform remote calculations across the globe; datasets we use include some for measuring carbon and land use change from space, modern land cover classifications at regional and global scales, and measurements of vegetation structure using space-borne LiDAR, without relying on data reported by the projects themselves.")), React.createElement(_components.div, {
    className: "uk-width-image-column  uk-image-float-right uk-margin-large-bottom@m uk-margin-medium-bottom"
  }, React.createElement(_components.div, {
    className: "uk-background-primary uk-overflow-hidden uk-position-relative  uk-border-rounded"
  }, React.createElement(_components.div, {
    className: "uk-width-1-1 uk-height-1-1 uk-background-sixth uk-position-absolute"
  }), React.createElement(_components.div, {
    className: "uk-cover-container"
  }, React.createElement(_components.img, {
    src: "/assets/images/what-is-pact/image-content-3.jpg",
    alt: "A narrow deep cliff-lined gorge leading out to sea",
    className: " uk-utility-object-fit-cover"
  }), React.createElement(_components.canvas, {
    width: "450",
    height: "600"
  })))), React.createElement(_components.h2, null, "PACTs are an important advance in transparency"), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "They allow the possibility of greatly reducing the transaction cost of bringing carbon credits to market, particularly by transferring the burden of proof from credit generators to technology companies and international standards agencies.")), React.createElement(_components.div, {
    className: "uk-wysiwyg-default uk-text-12 uk-text-fw-l uk-padding-medium-right@m"
  }, React.createElement(_components.p, null, "Better spatial layers, particularly maps of habitat suitability for wildlife and indicators of human development, enable us to estimate consequences for people and nature. We aim to apply the same scrutiny and metrics to our evaluations of credits generated by some of the most advanced negative carbon technologies.")))))), "\n", React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-dark uk-light uk-position-z-index  ",
    id: "six-dimensions-of-pact"
  }, React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement(_components.div, {
    className: "uk-width-text-column-title"
  }, React.createElement(_components.p, {
    className: "uk-style-secondary uk-text-8 uk-text-ls-reduce uk-margin-xsmall-top uk-scrollspy-inview "
  }, "Six dimensions of PACT")), React.createElement("div", {
    className: "uk-three-column-in-place@m"
  }, React.createElement(HeadedStatement, {
    color: "tertiary",
    title: "Additionality"
  }, React.createElement(_components.p, null, "Does the project have a provable impact which would not have happened without funding?")), React.createElement(HeadedStatement, {
    color: "primary",
    title: "Permanence"
  }, React.createElement(_components.p, null, "How long will the carbon benefits of the project last?")), React.createElement(HeadedStatement, {
    color: "quaternary",
    title: "Leakage"
  }, React.createElement(_components.p, null, "Can we account for the displacement of environmentally destructive activities to other locations?")), React.createElement(HeadedStatement, {
    color: "secondary",
    title: "Biodiversity"
  }, React.createElement(_components.p, null, "Are healthy ecosystems supported by the project?")), React.createElement(HeadedStatement, {
    color: "seventh",
    title: "Livelihoods"
  }, React.createElement(_components.p, null, "Are local communities supported by the project?")), React.createElement(HeadedStatement, {
    color: "quinary",
    title: "Justice"
  }, React.createElement(_components.p, null, "Have the voices and needs of local people been considered?"))))))), "\n", React.createElement(_components.section, {
    className: "uk-section uk-position-relative  uk-background-dark uk-light uk-position-z-index  ",
    id: ""
  }, React.createElement(_components.div, {
    className: "uk-container uk-container-medium uk-position-offset-right uk-position-relative uk-position-z-index"
  }, React.createElement(_components.div, {
    className: "uk-grid"
  }, React.createElement(_components.div, {
    className: "uk-width-expand@m"
  }, React.createElement("div", {
    className: "uk-text-center"
  }, React.createElement(Button, {
    target: "_blank",
    url: "https://4c.cst.cam.ac.uk/about",
    style: "primary"
  }, "Find out more about the science")))))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
