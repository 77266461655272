import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/src/content/what-is-pact.md";
import React, {useMemo} from "react";
import {MDXProvider} from "@mdx-js/react";
import {HeadTag} from "../components/HeadTag";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {Button} from "../components/Button";
import {CallToAction} from "../components/CallToAction";
import {Partner} from "../components/Partner";
import {SocialLink} from "../components/SocialLink";
import {HeadedStatement} from "../components/HeadedStatement";
import {TableOfContents} from "../components/TableOfContents";
function Template(props) {
  var mdx = props.data.mdx;
  var frontmatter = mdx.frontmatter;
  var components = useMemo(function () {
    return {
      Button: Button,
      CallToAction: CallToAction,
      HeadedStatement: HeadedStatement,
      Partner: Partner,
      SocialLink: SocialLink,
      TableOfContents: TableOfContents
    };
  }, []);
  var anyTitle = frontmatter.displayTitle || frontmatter.displayText || frontmatter.displayPreTitle;
  return React.createElement(React.Fragment, null, React.createElement(Header, null), React.createElement("main", null, (frontmatter.heroImage || anyTitle) && React.createElement("section", {
    className: "uk-section uk-section-hero uk-position-relative",
    id: "intro"
  }, React.createElement("div", {
    className: "uk-position-cover uk-background-nav-wash"
  }), frontmatter.heroImage && React.createElement("div", {
    className: "uk-position-cover"
  }, React.createElement("div", {
    className: "uk-cover-container uk-height-1-1"
  }, React.createElement("img", {
    src: "/assets/images/" + frontmatter.heroImage,
    className: "uk-utility-object-fit-cover uk-animation-slow-zoom"
  }), React.createElement("canvas", {
    width: "1600",
    height: "900"
  }))), anyTitle && React.createElement("div", {
    className: "uk-position-relative uk-position-z-index"
  }, React.createElement("div", {
    className: "uk-container uk-container-xxsmall uk-light uk-position-z-index uk-position-relative uk-animation-shear-overflow"
  }, frontmatter.displayPreTitle && React.createElement("h2", {
    className: "uk-style-meta uk-color-primary uk-text-meta-right uk-text-13"
  }, frontmatter.displayPreTitle), frontmatter.displayTitle && React.createElement("div", {
    className: "uk-text-2 uk-style-secondary uk-text-fw-l uk-text-ls-reduce"
  }, frontmatter.displayTitle), frontmatter.displayText && React.createElement("p", {
    className: " uk-text-9 uk-text-fw-l",
    dangerouslySetInnerHTML: {
      __html: frontmatter.displayText
    }
  })), frontmatter.displayPreTitle ? React.createElement("div", {
    className: "uk-position-internal-hero-circle-1"
  }) : React.createElement(React.Fragment, null, React.createElement("div", {
    className: "uk-position-hero-circle-1"
  }), React.createElement("div", {
    className: "uk-position-hero-circle-2"
  }), React.createElement("div", {
    className: "uk-position-hero-circle-3"
  })))), React.createElement(MDXProvider, {
    components: components
  }, props.children)), React.createElement(Footer, null));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export var Head = function Head(_ref) {
  var _mdx, _mdx$frontmatter;
  var location = _ref.location, params = _ref.params, data = _ref.data, pageContext = _ref.pageContext;
  var pageTitle = data === null || data === void 0 ? void 0 : (_mdx = data.mdx) === null || _mdx === void 0 ? void 0 : (_mdx$frontmatter = _mdx.frontmatter) === null || _mdx$frontmatter === void 0 ? void 0 : _mdx$frontmatter.title;
  return React.createElement(HeadTag, {
    pageTitle: pageTitle
  });
};
var pageQuery = "419537747";
